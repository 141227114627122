import React from 'react';

type PropsT = {
    className?: string;
    isDisabled?: boolean;
    onOutsideClick: (event: MouseEvent) => void;
};

class OutsideClickHandler extends React.PureComponent<PropsT> {
    wrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: any) => {
        const wrapperNode = this.wrapperRef?.current;
        if (!wrapperNode) {
            return;
        }

        if (!wrapperNode.contains(event.target)) {
            if (this.props.isDisabled) {
                return;
            }

            if (this.props.onOutsideClick) {
                this.props.onOutsideClick(event);
            }
        }
    };

    render() {
        const { className } = this.props;

        return (
            <div className={className} ref={this.wrapperRef}>
                {this.props.children}
            </div>
        );
    }
}

export default OutsideClickHandler;
