import React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './DropdownBaseLayout.scss';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import { DropdownOverlayPositionEnum } from '../../constants';

const cx = classNames.bind(styles);

export type DropdownBaseLayoutPropsT = {
    isOpen?: boolean;
    isInline?: boolean;
    className?: string;
    overlayClassName?: string;
    triggerNode: React.ReactNode;
    overlayNode: React.ReactNode;
    overlayPosition: DropdownOverlayPositionEnum;
    withoutScroll?: boolean;
    onClose: () => void;
};

const DropdownBaseLayout: React.FC<DropdownBaseLayoutPropsT> = React.memo((props) => {
    const {
        isOpen,
        withoutScroll,
        isInline,
        triggerNode,
        overlayNode,
        className,
        overlayPosition,
        overlayClassName,
        onClose,
    } = props;

    return (
        <div
            className={cs(
                cx('wrap', {
                    'wrap--is-inline': isInline,
                }),
                className,
            )}
        >
            <OutsideClickHandler isDisabled={!isOpen} onOutsideClick={onClose}>
                {triggerNode}
                {isOpen && (
                    <div
                        className={cs(
                            overlayClassName,
                            cx('overlay', {
                                'overlay--has-scroll': !withoutScroll,
                                [`overlay--position-${overlayPosition}`]: true,
                            }),
                        )}
                    >
                        {overlayNode}
                    </div>
                )}
            </OutsideClickHandler>
        </div>
    );
});

export default DropdownBaseLayout;
