import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './NotFoundPage.scss';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../AuthLayout/AuthLayout';
import AuthLayoutMessage, { AuthMessageIconsEnum } from 'common/layouts/AuthLayout/AuthLayoutMessage/AuthLayoutMessage';

const cx = classNames.bind(styles);

type PropsT = {};

const NotFoundPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    return (
        <>
            <PageTitle title={t('common:page-titles.404')} />
            <AuthLayout testSelector="not-found-page">
                <AuthLayoutMessage
                    iconType={AuthMessageIconsEnum.sleep}
                    title="404"
                    description={t('common:404-page.description')}
                    testSelector="not-found"
                />
            </AuthLayout>
        </>
    );
});

export default NotFoundPage;
