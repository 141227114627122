import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './Button.scss';
import cs from 'classnames';

import noop from 'lodash/noop';
import LoaderDots, { LoaderDotsThemeEnum } from 'common/components/LoaderDots/LoaderDots';

const cx = classNames.bind(styles);

export enum ButtonThemeEnum {
    primary = 'primary',
    secondary = 'secondary',
    danger = 'danger',
    transparent = 'transparent',
}

export type ButtonPropsT = {
    className?: string;
    theme?: ButtonThemeEnum;
    type?: 'button' | 'submit' | 'reset';
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isDisabled?: boolean;
    isToggled?: boolean;
    isLoading?: boolean;
    isFocused?: boolean;
    isPressed?: boolean;
    isNarrow?: boolean;
    isFullWidth?: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    testSelector?: string;
};

const DOTS_THEME = {
    [ButtonThemeEnum.primary]: LoaderDotsThemeEnum.light,
    [ButtonThemeEnum.secondary]: LoaderDotsThemeEnum.dark,
    [ButtonThemeEnum.danger]: LoaderDotsThemeEnum.light,
    [ButtonThemeEnum.transparent]: LoaderDotsThemeEnum.dark,
};

const Button: React.FC<ButtonPropsT> = (props) => {
    const {
        className,
        type,
        theme = ButtonThemeEnum.secondary,
        onClick,
        isLoading,
        isDisabled,
        isToggled,
        isFocused,
        isPressed,
        children,
        leftIcon,
        rightIcon,
        isNarrow,
        isFullWidth,
        testSelector,
    } = props;

    const isClickable = !isDisabled && !isToggled && !isLoading;

    return (
        <button
            className={cs(
                cx('button', {
                    [`button--theme-${theme}`]: true,
                    'button--isClickable': isClickable,
                    'button--isDisabled': isDisabled,
                    'button--isToggled': isToggled,
                    'button--isFocused': isFocused,
                    'button--isPressed': isPressed,
                    'button--isFullWidth': isFullWidth,
                    'button--isNarrowLeftPadding': leftIcon || isNarrow,
                    'button--isNarrowRightPadding': rightIcon || isNarrow,
                }),
                className,
            )}
            type={type || 'button'}
            disabled={!isClickable}
            data-test-selector={`${testSelector}_button`}
            onClick={isClickable ? onClick : noop}
        >
            <div
                className={cx('inner', {
                    'inner--isFullWidth': isFullWidth,
                })}
            >
                {leftIcon && (
                    <span
                        className={cx('icon', {
                            'icon--isLeft': true,
                            'icon--isHidden': isLoading,
                        })}
                    >
                        {leftIcon}
                    </span>
                )}
                <span
                    className={cx('text', {
                        'text--isHidden': isLoading,
                    })}
                >
                    {children}
                </span>
                {isLoading && (
                    <span className={cx('loader')}>
                        <LoaderDots theme={DOTS_THEME[theme]} />
                    </span>
                )}
                {rightIcon && (
                    <span
                        className={cx('icon', {
                            'icon--isRight': true,
                            'icon--isHidden': isLoading,
                        })}
                    >
                        {rightIcon}
                    </span>
                )}
            </div>
        </button>
    );
};

export default Button;
