import React from 'react';

import classNames from 'classnames/bind';
import styles from './LanguageSwitcherOption.scss';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';

const cx = classNames.bind(styles);

type PropsT = {
    flagCode: string | null;
    label: string;
};

const LanguageSwitcherOption: React.FC<PropsT> = (props) => {
    const { flagCode, label } = props;

    return (
        <>
            <FlagIcon countryCode={flagCode} className={cx('flag')} />
            <span>{label}</span>
        </>
    );
};

export default LanguageSwitcherOption;
