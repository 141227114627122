import * as React from 'react';

type PropsT = {
    className?: string;
};

const BigSleepIcon: React.FC<PropsT> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="130" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#D32406"
                    d="M106 31a7 7 0 010 14h61a7 7 0 010 14h22a7 7 0 010 14h-19a7 7 0 000 14h6a7 7 0 010 14H33a7 7 0 010-14H7a7 7 0 010-14h27a7 7 0 000-14H22a7 7 0 010-14h27a7 7 0 010-14h57zm87 56a7 7 0 110 14 7 7 0 010-14z"
                    opacity="0.1"
                />
                <path
                    fill="#FFF"
                    stroke="#D32406"
                    strokeLinecap="round"
                    strokeWidth="2.5"
                    d="M62 107c-12.15 0-22-9.626-22-21.5S49.85 64 62 64c.517 0 1.03.017 1.537.052A34.204 34.204 0 0163 58c0-18.778 15.222-34 34-34 14.991 0 27.716 9.702 32.239 23.17 1.071-.113 2.16-.17 3.261-.17 16.845 0 30.5 13.431 30.5 30 0 15.74-12.325 28.727-28 29.978V107H78.508m-4.512 0h-6.965"
                />
                <circle cx="97.5" cy="87.5" r="5.5" stroke="#D32406" strokeWidth="2.5" />
                <path
                    stroke="#D32406"
                    strokeLinecap="round"
                    strokeOpacity="0.3"
                    strokeWidth="2.5"
                    d="M107 34a19.036 19.036 0 0113.44 13.293"
                />
                <circle cx="128" cy="14" r="3" stroke="#D32406" strokeWidth="2" opacity="0.3" />
                <circle cx="159" cy="30" r="3" fill="#D32406" opacity="0.3" />
                <path
                    stroke="#D32406"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    d="M144.243 24.757l-8.359 8.359m-.127-8.359l8.359 8.359"
                    opacity="0.3"
                />
                <path
                    fill="#D32406"
                    d="M29.704 55v-3.338h-8.562l8.476-12.437V37H15.704v3.35h8.587l-8.538 12.363V55z"
                    opacity="0.3"
                />
                <path
                    fill="#D32406"
                    d="M41.704 59v-2.225h-6.116l6.055-8.291V47h-9.939v2.234h6.134l-6.099 8.241V59z"
                    opacity="0.5"
                />
                <path
                    fill="#D32406"
                    d="M34.704 74v-1.484h-3.67l3.633-5.527V66h-5.963v1.489h3.68l-3.659 5.495V74z"
                    opacity="0.9"
                />
                <path fill="#FFF" stroke="#D32406" strokeLinecap="round" strokeWidth="2.5" d="M71 70h12m29 0h12" />
            </g>
        </svg>
    );
};

BigSleepIcon.displayName = 'BigSleepIcon';

const storyProps: PropsT = {};

export { storyProps };
export default BigSleepIcon;
