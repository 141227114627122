import React from 'react';
import { useTranslation } from 'react-i18next';

import { LangEnum } from 'common/locales/i18n';
import classNames from 'classnames/bind';
import styles from './LanguageSwitcher.scss';
import LanguageSwitcherOption from 'common/components/LanguageSwitcher/LanguageSwitcherOption/LanguageSwitcherOption';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { CIMODE } from 'common/constants';
import { isNonNil } from 'common/utils';

const cx = classNames.bind(styles);

type ValueT = LangEnum | typeof CIMODE | null | undefined;
type PropsT = {
    lang?: ValueT;
    className?: string;
    isShowCIMCode?: boolean;
    isFullSize?: boolean;
    onChange?: (language: ValueT) => void;
    overlayPosition?: DropdownOverlayPositionEnum;
    hasChanges?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
};

type OptionT = {
    label: React.ReactElement;
    value: ValueT;
};

const LanguageSwitcher: React.FC<PropsT> = (props) => {
    const { t } = useTranslation();

    const { lang, isFullSize, onChange, isShowCIMCode, overlayPosition, className, hasChanges, onBlur, onFocus } =
        props;

    const options = React.useMemo(
        (): Array<OptionT> =>
            [
                {
                    label: <LanguageSwitcherOption flagCode="GB" label={isFullSize ? 'English' : 'ENG'} />,
                    value: LangEnum.en,
                },
                {
                    label: <LanguageSwitcherOption flagCode="DE" label={isFullSize ? 'Deutsch' : 'DE'} />,
                    value: LangEnum.de,
                },
                {
                    label: <LanguageSwitcherOption flagCode="PL" label={isFullSize ? 'Polish' : 'PL'} />,
                    value: LangEnum.pl,
                },
                {
                    label: <LanguageSwitcherOption flagCode="RU" label={isFullSize ? 'Русский' : 'RU'} />,
                    value: LangEnum.ru,
                },
                isShowCIMCode
                    ? {
                          label: <LanguageSwitcherOption flagCode={null} label="CIMCODE" />,
                          value: CIMODE,
                      }
                    : null,
            ].filter(isNonNil),
        [isFullSize],
    );

    const handleOptionClick = (selectedLang: OptionT['value']): void => {
        if (onChange) {
            onChange(selectedLang);
        }
    };

    const renderOption = (option: OptionT | null | undefined): React.ReactElement | null => option?.label || null;
    const getOptionValue = (option: OptionT) => option?.value;

    return (
        <DropdownInput<OptionT, ValueT>
            className={className}
            selectedValue={lang}
            placeholder={t('common:select-language')}
            options={options}
            onSelect={handleOptionClick}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.topLeft}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
};

export default LanguageSwitcher;
