import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor?: StyleGuideColorsEnum | 'currentColor';
    opacity?: number;
};

const CloseIcon: React.FC<PropsT> = (props) => {
    const { fillColor, opacity, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" {...rest}>
            <path
                d="M14.95 6.464L11.414 10l3.536 3.536-1.414 1.414-3.537-3.536-3.535 3.536-1.414-1.414 3.535-3.537L5.05 6.464 6.464 5.05 10 8.586l3.536-3.536 1.414 1.414z"
                fill={fillColor || StyleGuideColorsEnum.charcoal}
                fillRule="evenodd"
                opacity={opacity || 1}
            />
        </svg>
    );
};

CloseIcon.displayName = 'CloseIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandDark,
    opacity: 1,
};

export { storyProps };
export default CloseIcon;
