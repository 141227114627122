import * as React from 'react';

type PropsT = {
    className?: string;
};

const BigDoneIcon: React.FC<PropsT> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="130" viewBox="0 0 200 130" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M15 26h170v90H15z" />
                <path
                    fill="#8ABA2E"
                    fillOpacity="0.05"
                    d="M176 26a7 7 0 010 14h-40a7 7 0 010 14h22a7 7 0 010 14h-10.174c-4.874 0-8.826 3.134-8.826 7 0 2.577 2 4.91 6 7a7 7 0 010 14H62a7 7 0 010-14H23a7 7 0 010-14h40a7 7 0 000-14H38a7 7 0 010-14h40a7 7 0 010-14h98zm0 28a7 7 0 110 14 7 7 0 010-14z"
                />
                <g stroke="#8ABA2E" strokeWidth="2.5">
                    <path
                        fill="#FFF"
                        strokeLinecap="round"
                        d="M126.983 108.077c3.038-1.955 5.74-4.395 7.497-6.364m2.836-3.272h0a47.66 47.66 0 009.431-19.056 48.303 48.303 0 00-1.326-27.01 47.94 47.94 0 00-5.843-11.546 48.664 48.664 0 00-5.061-6.18 48.44 48.44 0 00-9.022-7.343 47.947 47.947 0 00-10.95-5.102 48.327 48.327 0 00-26.156-.926 47.613 47.613 0 00-12.256 4.868 47.933 47.933 0 00-9.605 7.198 47.811 47.811 0 00-9.027 11.963 47.76 47.76 0 00-4.72 13.37 48.348 48.348 0 00-.582 14.457 48.067 48.067 0 0013.261 28.202 48.147 48.147 0 0016.346 11.122 48.308 48.308 0 0022.85 3.413 47.783 47.783 0 0018.705-5.76"
                    />
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeOpacity="0.3"
                        d="M146.61 10.957v6m0 8v6m-10-10h6m8 0h6M63.61 113.957v4m0 8v4m-8-8h4m8 0h4"
                    />
                    <path
                        fill="#FFF"
                        strokeLinecap="round"
                        d="M85.293 28.658a41.582 41.582 0 00-5.955 2.79 41.948 41.948 0 00-8.404 6.297 42.34 42.34 0 00-2.984 3.195m-2.875 3.844a41.568 41.568 0 00-3.319 6.054"
                    />
                    <path
                        fill="#8ABA2E"
                        fillOpacity="0.3"
                        fillRule="nonzero"
                        d="M81.09 67.326a3.607 3.607 0 00-5.045.225 3.488 3.488 0 00.228 4.975L93.08 87.667c2.039 1.837 5.202 1.696 7.064-.315.078-.085.078-.085.153-.173l23.987-28.448a3.488 3.488 0 00-.463-4.96 3.606 3.606 0 00-5.03.457l-22.55 26.746-15.15-13.648z"
                    />
                </g>
            </g>
        </svg>
    );
};

BigDoneIcon.displayName = 'BigDoneIcon';

const storyProps: PropsT = {};

export { storyProps };
export default BigDoneIcon;
