import * as React from 'react';
import { useClickInterceptorProps } from 'common/components/ClickInterceptorLabel/hooks/use-click-interceptor-props';

type SpanPropsT = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

type PropsT = {
    className?: string;
    onClick?: () => void;
} & SpanPropsT;

const ClickInterceptorLabel: React.FC<PropsT> = (props) => {
    const { className, onClick, children, ...rest } = props;

    const clickInterceptorProps = useClickInterceptorProps({ onClick });

    return (
        <span {...clickInterceptorProps} className={className} {...rest}>
            {children}
        </span>
    );
};

export default React.memo(ClickInterceptorLabel) as typeof ClickInterceptorLabel;
