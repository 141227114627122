import * as React from 'react';

import 'flag-icon-css/css/flag-icon.css';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './FlagIcon.scss';

const cx = classNames.bind(styles);

type PropsT = {
    countryCode?: string | null;
    className?: string;
};

const FlagIcon: React.FC<PropsT> = React.memo((props) => {
    const { countryCode, className } = props;

    const preparedCountryCode = (countryCode || '').toLowerCase();

    const baseFlagClassName = `flag-icon-${preparedCountryCode}`;

    const isEmpty = !preparedCountryCode;

    return (
        <div className={cs(cx('wrap'), className)}>
            <div
                className={cs(
                    baseFlagClassName,
                    cx('flag', {
                        'flag--isEmpty': isEmpty,
                        'flag--hasCountryCode': !isEmpty,
                    }),
                )}
            />
        </div>
    );
});

export default FlagIcon;
