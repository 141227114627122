import { TourStatusEnum, DocumentTypeT } from 'common/utils/api/models';
import { ResourceLanguage } from 'i18next';
import { MS_IN_HOUR } from 'common/utils/time';

export enum CookieKeysEnum {
    acceptLanguage = 'accept-language',
}

export enum LangEnum {
    en = 'en',
    de = 'de',
    ru = 'ru',
    pl = 'pl',
}

export const CIMODE = 'cimode' as const;

export type LangConfigT = Record<LangEnum, ResourceLanguage>;

export enum QueryKeysEnum {
    lang = 'lang',
    successChangePassword = 'success-change-password',
    successSignUp = 'success-sign-up',
    anchor = 'anchor',
    returnUrl = 'return-url',
    sourceUrl = 'source-url',

    // auth
    email = 'email',
    oobCode = 'oobCode',

    trailerOrderId = 'trailer-order-id',

    truckOrderId = 'truck-order-id',

    // orders
    ordersPage = 'orders-page',
    ordersSort = 'orders-sort',
    ordersFilters = 'orders-filters',

    orderCreationDropOffDate = 'order-creation-drop-off-date',
    orderCreationPickUpDate = 'order-creation-pick-up-date',
    orderCreationPriceOfferId = 'order-creation-price-offer-id',
    orderCreationAdditionalServices = 'order-creation-additional-services',
    orderCreationPickUpDatesRange = 'order-creation-pick-up-dates-range',
    orderCreationDropOffDatesRange = 'order-creation-drop-off-dates-range',
    orderCreationComplete = 'order-creation-complete',

    // order-assignment
    assignment = 'assignment',
    assignmentTruckId = 'assignment-truck-id',
    assignmentTrailerId = 'assignment-trailer-id',
    sendingToSpot = 'sending-to-spot',

    timeWindowEdit = 'time-window-edit',

    // rates
    ratesPage = 'rates-page',
    ratesFromCountryCodesSearch = 'rates-from-country-codes-search',
    ratesToCountryCodesSearch = 'rates-to-country-codes-search',

    // members
    userId = 'user-id',
    contactId = 'contact-id',

    systemSettingsTab = 'system-settings-tab',
}

export const authRoutesEnum = {
    signIn: '/sign-in',
    signUp: '/sign-up',
    continueSignUp: '/continue-sign-up',
    selfCreateCompany: '/self-create-company',
    forgotPassword: '/forgot-password',
    changePassword: '/change-password',
    confirmEmail: '/confirm-email',
    home: '/',
};

export const externalRoutesSet = new Set([
    authRoutesEnum.signIn,
    authRoutesEnum.signUp,
    authRoutesEnum.forgotPassword,
    authRoutesEnum.changePassword,
    authRoutesEnum.confirmEmail,
    authRoutesEnum.continueSignUp,
    authRoutesEnum.selfCreateCompany,
]);

export enum CommonRoutesEnum {
    home = '/',
    profile = '/profile',
    account = '/account',
    team = '/team',
    orders = '/orders',
    transportOrders = '/transport-orders',
    notFound = '/not-found',
    accessDenied = '/access-denied',
}

export enum OMSRoutesEnum {
    orderDetails = '/orders/:orderId',
    newOrder = '/orders/new',
    newOrderOffers = '/orders/new/:rfqId/offers',
    newOrderDetails = '/orders/new/:rfqId/offers/:offerId/details',
    newOrderDetailsWithLane = '/orders/new/:rfqId/lanes/:laneId/offers/:offerId/details',
}

export enum RFQRoutesEnum {
    list = '/rfqs',
    details = '/rfqs/:rfqId/details',
}

export enum ProfileRoutesEnum {
    basic = '/profile/basic',
    documents = '/profile/documents',
    shipperContracts = '/profile/shipper-contracts',
}

export enum ProfileDocumentsRoutesEnum {
    requiredDocuments = '/profile/documents/required',
    optionalDocuments = '/profile/documents/optional',
    carrierContracts = '/profile/documents/carrier-contracts',
    shipperContracts = '/profile/documents/shipper-contracts',
}

export enum ShipperContractRoutesEnum {
    shipperContractDetails = '/profile/shipper-contracts/:contractId/details',
    shipperContractLanes = '/profile/shipper-contracts/:contractId/lanes',
}

export enum ShipperContractsRoutesEnum {
    shipperContract = '/profile/shipper-contracts/:contractId',
    shipperContractLaneDetails = '/profile/shipper-contracts/:contractId/lane/:laneId',
}

export const BACKEND_URL = '/api/v1';

export enum CompanyTypeEnum {
    shipper = 'SHIPPER',
    broker = 'BROKER',
    carrier = 'CARRIER',
}

export enum TrailerTypeEnum {
    box = 'BOX',
    tilt = 'TILT',
    reef = 'REEFER',
}

export enum QueryFiltersKeysEnum {
    quickFilter = 'quickFilter',
    includeStatuses = 'includeStatuses',
    trailerModel = 'trailerModel',
    origin = 'origin',
    destination = 'destination',
    pickupDateFrom = 'pickupDockingHoursFrom',
    pickupDateTo = 'pickupDockingHoursTo',
    dropOffDateFrom = 'dropOffDockingHoursFrom',
    dropOffDateTo = 'dropOffDockingHoursTo',
    createdDateFrom = 'addedDateFrom',
    createdDateTo = 'addedDateTo',
    createdById = 'createdById',
    createdByName = 'createdByName',
    receiveDatesFrom = 'receiveDatesFrom',
    receiveDatesTo = 'receiveDatesTo',
    source = 'source',
    orderType = 'orderType',
    plateNumber = 'plateNumber',
    excludedStatus = 'excludedStatus',
    excludedTrailersStatus = 'excludedTrailersStatus',
    excludedTruckStatus = 'excludedTruckStatus',
    excludedTrailerType = 'excludedTrailerType',
    truckModel = 'truckModel',
    truckVIN = 'vin',
    keyAccountManagerId = 'keyAccountManagerId',
    keyAccountManagerName = 'keyAccountManagerName',
    dispatcherId = 'dispatcherId',
    dispatcherName = 'dispatcherName',
    countryCode = 'countryCode',
    partnerType = 'partnerType',
    searchText = 'searchText',
    withUpdates = 'withUpdates',
    carrierId = 'carrierId',
    carrierName = 'carrierName',
    shipperId = 'shipperId',
    shipperName = 'shipperName',
    emissionStandard = 'emissionStandard',
    trailerType = 'trailerType',
    trailerId = 'trailerId',
    dictTrailerId = 'dictTrailerId',
    address = 'address',
    originAddress = 'originAddress',
    destinationAddress = 'destinationAddress',
    pickupAddress = 'pickupAddress',
    dropoffAddress = 'dropoffAddress',
    pickupDates = 'pickupDates',
    pickupTimeWindow = 'pickupTimeWindow',
    dropoffDates = 'dropoffDates',
    dropoffTimeWindow = 'dropoffTimeWindow',
    minPrice = 'minPrice',
    maxPrice = 'maxPrice',
    driverId = 'driverId',
    driverName = 'driverName',
    name = 'name',
}

export const CURRENCY_SYMBOLS = {
    EUR: '€',
};

export enum CurrencyEnum {
    EUR = 'EUR',
}

type CurrencySettingsT = {
    symbol: string;
    isFirstSymbol: boolean;
    decimalSeparator: string;
    thousandSeparator: string;
    minimumSignificantDigits: number;
};

export const CURRENCY_SETTINGS: Record<CurrencyEnum, CurrencySettingsT> = {
    [CurrencyEnum.EUR]: {
        symbol: CURRENCY_SYMBOLS.EUR,
        isFirstSymbol: false,
        decimalSeparator: '.',
        thousandSeparator: ' ',
        minimumSignificantDigits: 2,
    },
};

const SEC_IN_HOUR = 60 * 60;
export enum TimeZonesEnum {
    'UTC-0' = 0,
    'UTC+1' = 1 * SEC_IN_HOUR,
    'UTC+2' = 2 * SEC_IN_HOUR,
    'UTC+3' = 3 * SEC_IN_HOUR,
    'UTC+4' = 4 * SEC_IN_HOUR,
}

export const DEFAULT_CARRIER_COMPANY_ID = 'defaultCarrierCompanyId';
export const DEFAULT_SHIPPER_COMPANY_ID = 'defaultShipperCompanyId';
export const DEFAULT_BROKER_COMPANY_ID = 'defaultBrokerCompanyId';

export enum DaysEnum {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export const WEEK_DAYS_ORDER = [
    DaysEnum.monday,
    DaysEnum.tuesday,
    DaysEnum.wednesday,
    DaysEnum.thursday,
    DaysEnum.friday,
    DaysEnum.saturday,
    DaysEnum.sunday,
];

export const PHONE_NUMBER_PREFIX = '+';

export const ZIP_CODES_SEPARATOR = ';';

export const UNIT = 5; // 5px
export const DEFAULT_ICON_SIZE = 4 * UNIT;

export enum StyleGuideColorsEnum {
    white = '#FFFFFF',
    light = '#e2e5ed',
    gray = '#9ea0a5',
    charcoal = '#3e3f42',
    black = '#000000',
    brandDark = '#4b6518',
    brandAccent = '#8ABA2E',
    brandAccent5 = '#dbeac0',
    tomatoRed = '#d32406',
    slate = '#6b6c6f',
    blazeOrange = '#fa6400',
    orange = '#ea9200',
    squash = '#f6a623',
    mediumGreen = '#39b54a',
    dodgerBlue = '#32c5ff',
    transparent = 'transparent',
    currentColor = 'currentColor',
}

// https://stackoverflow.com/a/65773709
export const AUTO_COMPLETE_OFF_FIX = 'one-time-code';

export const ALL_CARRIERS_ID = 'all';

export enum SocialMediaEnum {
    skype = 'skype',
    whatsapp = 'whatsapp',
    viber = 'viber',
    telegram = 'telegram',
    zoom = 'zoom',
    msTeams = 'msTeams',
    webEx = 'webEx',
}

export const ADDITIONAL_SERVICES_ID_MAP = {
    EURO6: 40,
    TIME_SLOTS_APPOINTMENT_BY_BROKER: 44,
};

export const ADDITIONAL_SERVICES_ENUM_MAP = {
    EURO6: 'EURO_VI',
};

export const alwaysDisabledAdditionalServiceIdsSet = new Set([
    ADDITIONAL_SERVICES_ID_MAP.TIME_SLOTS_APPOINTMENT_BY_BROKER,
]);

export const FINISHED_TOUR_STATUSES_SET = new Set([TourStatusEnum.finished, TourStatusEnum.canceled]);

export enum UnitTypeEnum {
    shipments = 'shipments',
    eur1Pallets = 'eur1pallets',
    eur2Pallets = 'eur2pallets',
    other = 'other',
    euroAbbreviation = 'eur-abbreviation',
    percentsAbbreviation = 'percents-abbreviation',
    kilogramsAbbreviation = 'kilograms-abbreviation',
    metersAbbreviation = 'meters-abbreviation',
    kilometersAbbreviation = 'kilometers-abbreviation',
    kilometersPerHourAbbreviation = 'kilometers-per-hour-abbreviation',
    litersAbbreviation = 'liters-abbreviation',
    days = 'days',
    oneHundredKilometersAbbreviation = '100-kilometers-abbreviation',
    hoursAbbreviation = 'hours-abbreviation',
    minutesAbbreviation = 'minutes-abbreviation',
    milliSecondsAbbreviation = 'milli-seconds-abbreviation',
    secondsAbbreviation = 'seconds-abbreviation',
}

export enum TemperatureModeEnum {
    deepFrozen = 'deep-frozen',
    ultraFresh = 'ultra-fresh',
    dry = 'dry',
    chilled = 'chilled',
    ambient = 'ambient',
}

export const temperatureRanges: Record<TemperatureModeEnum, [number, number]> = {
    [TemperatureModeEnum.deepFrozen]: [-25, -18],
    [TemperatureModeEnum.ultraFresh]: [0, 4],
    [TemperatureModeEnum.dry]: [0, 25],
    [TemperatureModeEnum.chilled]: [5, 7],
    [TemperatureModeEnum.ambient]: [14, 21],
};

export enum RuleImpactStrategyEnum {
    exactValue = 'EXACT',
    percent = 'PERCENTS',
}

export enum KeyEnum {
    escape = 'Escape',
}

export const ALL_DAY_RANGE: TimeWindowT = [0, 24 * MS_IN_HOUR];

export enum AssetTypeEnum {
    trailer = 'trailer',
    truck = 'truck',
}

export enum EmissionStandartEnum {
    euro5 = 'EURO_5',
    euro6 = 'EURO_6',
}

export type ApiEmissionClassT = Api2.Definitions.EmissionStandard;
export enum EmissionClassEnum {
    euro5 = 'EURO_V',
    euro6 = 'EURO_VI',
}

export enum DirectionEnum {
    destination = 'destination',
    origin = 'origin',
}

export const documentsWithoutExpirySet = new Set<DocumentTypeT>([
    'EVIDENCE_OF_TAX_VAT',
    'CERTIFICATE_OF_INCORPORATION',
]);

export const FAKE_NO_LIMIT_VALID_TILL_YEAR = 100;
