import * as React from 'react';

import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './AuthLayout.scss';

import LanguageSwitcher from 'common/components/LanguageSwitcher/LanguageSwitcher';
import Logo from 'common/components/Logo/Logo';
import { changeLanguage, LangEnum } from 'common/locales/i18n';
import { getCurrentYear } from 'common/utils/time';

const cx = classNames.bind(styles);

type PropsT = {
    title?: string;
    testSelector?: string;
};

const AuthLayout: React.FC<PropsT> = React.memo((props) => {
    const { children, title, testSelector } = props;
    const { t, i18n } = useTranslation();
    return (
        <div className={cx('container')} data-test-selector={testSelector}>
            <div className={cx('left')}>
                <div className={cx('background')} />
            </div>
            <div className={cx('right')}>
                <div className={cx('inner')}>
                    <div className={cx('header')}>
                        <div className={cx('header-inner')}>
                            <Logo />
                        </div>
                    </div>
                    {!!title && <div className={cx('title')}>{title}</div>}
                    <div className={cx('content')}>
                        <div className={cx('content-inner')}>{children}</div>
                    </div>
                    <div className={cx('footer')}>
                        <div className={cx('footer-inner')}>
                            <LanguageSwitcher
                                className={cx('language-switcher')}
                                lang={i18n.language as LangEnum}
                                onChange={(language) => {
                                    if (!language) {
                                        return;
                                    }

                                    changeLanguage(language);
                                }}
                            />
                            <div className={cx('footer')}>
                                {t('common:copyright', {
                                    year: getCurrentYear(),
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default AuthLayout;
