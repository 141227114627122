import React, { CSSProperties } from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './TooltipContent.scss';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    theme: TooltipContentThemeEnum;
    isNoWrap?: boolean;
    isCenter?: boolean;
    width?: number;
};

export enum TooltipContentThemeEnum {
    white = 'white',
    black = 'black',
}

const TooltipContent: React.FC<PropsT> = (props) => {
    const { children, className, theme, width, isNoWrap, isCenter } = props;

    const style: CSSProperties = {};
    if (width) {
        style.width = `${width}px`;
    }

    return (
        <div
            className={cs(
                cx('inner', {
                    'inner--no-wrap': isNoWrap,
                    'inner--is-center': isCenter,
                    [`inner--theme-${theme}`]: true,
                }),
                className,
            )}
            style={style}
        >
            {children}
        </div>
    );
};

export default TooltipContent;
