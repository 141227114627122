import * as React from 'react';

type PropsT = {
    className?: string;
};

const ArrowsIcon: React.FC<PropsT> = (props) => {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
            <path d="M13 12l-3 3-3-3h6zm-3-7l3 3H7l3-3z" fill="#9EA0A5" fillRule="evenodd" />
        </svg>
    );
};

ArrowsIcon.displayName = 'ArrowsIcon';

const storyProps: PropsT = {};

export { storyProps };
export default ArrowsIcon;
