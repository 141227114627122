import * as React from 'react';
import { Helmet } from 'react-helmet';

type PropsT = {
    title: React.ReactNode;
};

const PageTitle: React.FC<PropsT> = React.memo((props) => {
    const { title } = props;

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
});

export default PageTitle;
