import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './TransparentTrigger.scss';

const cx = classNames.bind(styles);

export enum ReflectionThemeEnum {
    halfTransparentLight = 'half-transparent-light',
    light = 'light',
}

export type PropsT = {
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    isPressed?: boolean;
    isDisabled?: boolean;
    isToggled?: boolean;
    withoutBorderRadius?: boolean;
    reflectionTheme?: ReflectionThemeEnum;
    className?: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label?: React.ReactNode;
    labelClassName?: string;
    testSelector?: React.ReactNode;
    spaces?: 'xxs' | 'xs' | 's';
};

const DEFAULT_SPACES = 's';

const TransparentTrigger: React.FC<PropsT> = (props) => {
    const {
        onClick,
        isPressed,
        isDisabled,
        isToggled,
        label,
        labelClassName,
        leftIcon,
        rightIcon,
        className,
        withoutBorderRadius,
        testSelector,
        reflectionTheme,
        spaces,
    } = props;

    return (
        <div
            className={cs(
                cx('trigger', {
                    'trigger--isClickable': !isDisabled,
                    'trigger--isPressed': isPressed,
                    'trigger--isToggled': isToggled,
                    'trigger--isOnlyIcon': !!(leftIcon || rightIcon) && !label,
                    'trigger--hasLeftIconWithLabel': !!leftIcon && !!label,
                    'trigger--hasRightIconWithLabel': !!rightIcon && !!label,
                    'trigger--withoutBorderRadius': !!withoutBorderRadius,
                    [`trigger--spaces-${spaces || DEFAULT_SPACES}`]: true,
                    [`trigger--reflection-theme-${reflectionTheme || ReflectionThemeEnum.halfTransparentLight}`]: true,
                }),
                className,
            )}
            data-test-selector={`${testSelector}_trigger`}
            onClick={onClick}
        >
            {!!leftIcon && <div className={cx('trigger__icon')}>{leftIcon}</div>}
            {!!label && (
                <div
                    className={cs(
                        cx('trigger__label', {
                            [`trigger__label--spaces-${spaces || DEFAULT_SPACES}`]: true,
                            'trigger__label--hasLeftIcon': !!leftIcon,
                            'trigger__label--hasRightIcon': !!rightIcon,
                        }),
                        labelClassName,
                    )}
                >
                    {label}
                </div>
            )}
            {!!rightIcon && <div className={cx('trigger__icon')}>{rightIcon}</div>}
        </div>
    );
};

export default TransparentTrigger;
