import i18n, { Resource } from 'i18next';
import React from 'react';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import merge from 'lodash/merge';
import qs from 'qs';
import cookie from 'js-cookie';

import commonEN from './en_GB/en_GB.json';
import commonDE from './de_DE/de_DE.json';
import commonRU from './ru/ru.json';
import commonPL from './pl/pl.json';

import { CIMODE, CookieKeysEnum, LangConfigT, LangEnum, QueryKeysEnum } from '../constants';
import { isNonNil } from 'common/utils';

const commonResources: LangConfigT = {
    [LangEnum.en]: {
        common: commonEN,
    },
    [LangEnum.de]: {
        common: commonDE,
    },
    [LangEnum.pl]: {
        common: commonPL,
    },
    [LangEnum.ru]: {
        common: commonRU,
    },
};

const DEFAULT_LANGUAGE = LangEnum.en;

const detectLanguage = (): LangEnum => {
    const hashSearch = (window.location.hash || '').split('?')[1];
    const queryLanguage = qs.parse(hashSearch || '')[QueryKeysEnum.lang];

    const cookieLanguage = cookie.get(CookieKeysEnum.acceptLanguage);
    const browserLanguage = window.navigator.language.slice(0, 2);

    const detectedLanguage = (queryLanguage || cookieLanguage || browserLanguage) as LangEnum;

    const isValid = Object.values(LangEnum).includes(detectedLanguage);
    if (!isValid) {
        return DEFAULT_LANGUAGE;
    }

    return detectedLanguage;
};

const isDebugMode = window.location.href.includes('localise=true');

class DebugKeysPostprocessor {
    name = 'debugKeys';

    type = 'postProcessor';

    process(value: string, key: string): any {
        if (isDebugMode) {
            return React.createElement('span', { title: key }, value);
        }

        return value;
    }
}

export const init = (projectResources: Resource, projectNsName: string): void => {
    const lang = detectLanguage();
    i18n
        // @ts-ignore
        .use(new DebugKeysPostprocessor())
        .use(initReactI18next)
        .use(intervalPlural)
        .init({
            ns: ['common', projectNsName],
            defaultNS: projectNsName,
            resources: merge(commonResources, projectResources),
            lng: lang,
            keySeparator: '.',
            simplifyPluralSuffix: false,
            pluralSeparator: '_',
            postProcess: [isDebugMode ? 'debugKeys' : null].filter(isNonNil),
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        });

    moment.locale(lang);
};

export const changeLanguage = (selectedLang: LangEnum | typeof CIMODE): void => {
    let lang = selectedLang;

    const isValid = selectedLang === CIMODE || Object.values(LangEnum).includes(selectedLang);
    if (!isValid) {
        lang = DEFAULT_LANGUAGE;
    }

    i18n.changeLanguage(lang);

    if (selectedLang !== CIMODE) {
        cookie.set(CookieKeysEnum.acceptLanguage, lang);

        moment.locale(lang);
    }
};

export { LangEnum };
