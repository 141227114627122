import { useCallback } from 'react';

type OnClickCallbackT = (event?: TODO) => void;

type SourcePropsT = { onClick?: OnClickCallbackT | null | undefined };
type PropsT = { onClick: OnClickCallbackT };

export const useClickInterceptorProps = (sourceProps: SourcePropsT): PropsT => {
    const { onClick, ...restSourceProps } = sourceProps;

    const handleClick: OnClickCallbackT = useCallback(
        (event) => {
            event.stopPropagation();

            if (onClick) {
                onClick(event);
            }
        },
        [onClick],
    );

    return {
        ...restSourceProps,
        onClick: handleClick,
    };
};
