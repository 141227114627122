/* eslint-disable global-require */
const importAllPolyfills = () => {
    require('whatwg-fetch');
    require('intl');
    require('url-polyfill');
    require('core-js/web/dom-collections');
    require('core-js/features/set');
    require('core-js/features/map');
    require('core-js/features/string');
    require('core-js/features/array');
    require('core-js/features/object');
};

export default importAllPolyfills;
