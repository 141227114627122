/* NB: Naming rule: {vertical}-{horizontal} */
export enum DropdownOverlayPositionEnum {
    topLeft = 'top-left',
    topRight = 'top-right',
    topCenter = 'top-center',
    bottomLeft = 'bottom-left',
    bottomRight = 'bottom-right',
    bottomCenter = 'bottom-center',
    topCenterTrigger = 'top-center-trigger',
}
