import { init, LangEnum } from 'common/locales/i18n';
import { LangConfigT } from 'common/constants';

import projectEN from './en_GB/en_GB.json';
import projectDE from './de_DE/de_DE.json';
import projectPL from './pl/pl.json';
import projectRU from './ru/ru.json';

const projectResources: LangConfigT = {
    [LangEnum.en]: {
        project: projectEN,
    },
    [LangEnum.de]: {
        project: projectDE,
    },
    [LangEnum.pl]: {
        project: projectPL,
    },
    [LangEnum.ru]: {
        project: projectRU,
    },
};

init(projectResources, 'project');
