import React from 'react';
import styles from './LoaderDots.scss';
import cs from 'classnames';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export enum LoaderDotsThemeEnum {
    light = 'light',
    dark = 'dark',
}

export type PropsT = JSX.IntrinsicElements['span'] & {
    theme?: LoaderDotsThemeEnum;
};

export const LoaderDots: React.FC<PropsT> = (props) => {
    const { className, theme = LoaderDotsThemeEnum.dark, ...rest } = props;

    return (
        <span className={cs(cx('dots'), className)} {...rest}>
            <span
                className={cx('dot', {
                    'dot--1': true,
                    [`dot--theme-${theme}`]: true,
                })}
            />
            <span
                className={cx('dot', {
                    'dot--2': true,
                    [`dot--theme-${theme}`]: true,
                })}
            />
            <span
                className={cx('dot', {
                    'dot--3': true,
                    [`dot--theme-${theme}`]: true,
                })}
            />
        </span>
    );
};

export default LoaderDots;
