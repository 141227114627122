import { DEFAULT_BROKER_COMPANY_ID, DEFAULT_CARRIER_COMPANY_ID, DEFAULT_SHIPPER_COMPANY_ID } from 'common/constants';

export const isNonNil = <V>(v: V): v is NonNullable<V> => {
    return v !== undefined && v !== null;
};

export const checkIsDefaultCompanyId = (companyId: CompanyIdT): boolean => {
    return (
        companyId === DEFAULT_CARRIER_COMPANY_ID ||
        companyId === DEFAULT_SHIPPER_COMPANY_ID ||
        companyId === DEFAULT_BROKER_COMPANY_ID
    );
};
