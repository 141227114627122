import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { clientConfig } from 'common/utils/client-config';

export const initSentry = (): void => {
    if (!clientConfig.isProduction) {
        console.info('skip sentry');
        return;
    }

    Sentry.init({
        dsn: 'https://d36e1d102dfa487a9b8b48b0841af8ab@o473400.ingest.sentry.io/5508271',
        integrations: [new Integrations.BrowserTracing()],

        tracesSampleRate: 1.0,
    });
};
