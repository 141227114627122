import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    baseColor?: StyleGuideColorsEnum;
};

const BigWarningIcon: React.FC<PropsT> = (props) => {
    const baseColor = props?.baseColor || StyleGuideColorsEnum.tomatoRed;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="130" viewBox="0 0 200 130" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h200v130H0z" />
                <path
                    fill={baseColor}
                    d="M103 27a7 7 0 010 14h64a7 7 0 010 14h22a7 7 0 010 14h-19a7 7 0 000 14h6a7 7 0 010 14h-52a7.026 7.026 0 01-1.5-.161A7.026 7.026 0 01121 97H40a7 7 0 010-14H7a7 7 0 010-14h34a7 7 0 000-14H22a7 7 0 010-14h34a7 7 0 010-14h47zm90 56a7 7 0 110 14 7 7 0 010-14z"
                    opacity="0.1"
                />
                <path
                    fill="#FFF"
                    stroke={baseColor}
                    strokeLinecap="round"
                    strokeWidth="2.5"
                    d="M138.577 93.075a54.587 54.587 0 01-7.933 5.299l.165 14.083a2 2 0 01-3.22 1.609l-12.663-9.743A66.553 66.553 0 01100 106c-30.376 0-55-20.147-55-45s24.624-45 55-45 55 20.147 55 45c0 6.879-1.886 13.397-5.259 19.227a42.703 42.703 0 01-2.015 3.154m-2.533 3.273a47.462 47.462 0 01-2.906 3.122"
                />
                <path
                    fill={baseColor}
                    fillRule="nonzero"
                    d="M101.965 72.105h-3.018l-.176-30.761h3.399l-.205 30.761zm-3.516 9.815c0-.684.19-1.255.572-1.714.38-.459.913-.688 1.596-.688.684 0 1.216.23 1.597.688.38.459.571 1.03.571 1.714 0 .664-.19 1.216-.571 1.655-.381.44-.913.66-1.597.66-.683 0-1.216-.22-1.596-.66-.381-.44-.572-.991-.572-1.655z"
                />
            </g>
        </svg>
    );
};

BigWarningIcon.displayName = 'BigWarningIcon';

const storyProps: PropsT = {
    baseColor: StyleGuideColorsEnum.tomatoRed,
};

export { storyProps };
export default BigWarningIcon;
