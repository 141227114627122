import React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './Tooltip.scss';

const cx = classNames.bind(styles);

/* NB: Naming rule: {vertical}-{horizontal} */
export enum TooltipPositionEnum {
    centerLeft = 'center-left',
    topLeft = 'top-left',
    topCenter = 'top-center',
    topRight = 'top-right',
    centerRight = 'center-right',
    bottomCenter = 'bottom-center',
    bottomLeft = 'bottom-left',
    bottomRight = 'bottom-right',
}

export enum TooltipThemeEnum {
    white = 'white',
    black = 'black',
}

export type PropsT = {
    tooltipNode: React.ReactNode | null;
    className?: string;
    position: TooltipPositionEnum;
    theme: TooltipThemeEnum;
    delay?: number;
    isInline?: boolean;
    children: (isShow: boolean, childrenClassName?: string) => React.ReactElement | null;
};

const DEFAULT_SHOW_TOOLTIP_DELAY = 300;

let timeoutId: NodeJS.Timeout | null = null;

const Tooltip: React.FC<PropsT> = (props) => {
    const { tooltipNode, isInline, children, className, position, theme, delay } = props;

    const [isShow, setShow] = React.useState<boolean>(false);

    if (!tooltipNode) {
        return children(isShow);
    }

    const handleOpen = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const timeout = typeof delay === 'number' ? delay : DEFAULT_SHOW_TOOLTIP_DELAY;

        timeoutId = setTimeout(() => {
            setShow(true);
        }, timeout);
    };

    const handleClose = () => {
        setShow(false);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    };

    const childrenClassName = cx('children');

    return (
        <div
            className={cs(
                cx('wrap', {
                    'wrap--show': isShow,
                    'wrap--inline': isInline,
                    [`wrap--position-${position}-theme-${theme}`]: true,
                }),
                className,
            )}
            onMouseEnter={handleOpen}
            onClick={() => {
                handleClose();
                handleOpen();
            }}
            onMouseLeave={handleClose}
        >
            {children(isShow, childrenClassName)}
            {isShow && (
                <div
                    className={cx('tooltip', {
                        [`tooltip--position-${position}`]: true,
                    })}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                    }}
                >
                    <div
                        className={cx('tooltip__inner', {
                            [`tooltip__inner--theme-${theme}`]: true,
                            [`tooltip__inner--position-${position}`]: true,
                        })}
                    >
                        {tooltipNode}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
