import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './AuthLayoutMessage.scss';

import BigDoneIcon from 'common/icons/BigDoneIcon';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import Button, { ButtonPropsT } from 'common/components/Button/Button';
import BigSleepIcon from 'common/icons/BigSleepIcon';

const cx = classNames.bind(styles);

export enum AuthMessageIconsEnum {
    success = 'success',
    warning = 'warning',
    sleep = 'sleep',
}

type PropsT = {
    iconType: AuthMessageIconsEnum;
    title: React.ReactNode;
    description?: React.ReactNode;
    testSelector?: string;
    actionsProps?: Array<ButtonPropsT & { children: React.ReactNode }>;
};

const AuthLayoutMessage: React.FC<PropsT> = React.memo((props) => {
    const { iconType, title, description, testSelector, actionsProps } = props;

    let iconNode: React.ReactNode = null;
    if (iconType === AuthMessageIconsEnum.success) {
        iconNode = <BigDoneIcon />;
    }
    if (iconType === AuthMessageIconsEnum.warning) {
        iconNode = <BigWarningIcon />;
    }
    if (iconType === AuthMessageIconsEnum.sleep) {
        iconNode = <BigSleepIcon />;
    }

    return (
        <div className={cx('container')} data-test-selector={testSelector}>
            <div className={cx('icon')}>{iconNode}</div>
            <div className={cx('title')}>{title}</div>
            <div className={cx('description')}>{description}</div>
            {!!actionsProps?.length && (
                <div className={cx('actions')}>
                    {actionsProps?.map((actionProps, index) => (
                        <Button key={index} {...actionProps} />
                    ))}
                </div>
            )}
        </div>
    );
});

export default AuthLayoutMessage;
